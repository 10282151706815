<template>
  <div class="desktopLayoutClass">
    <v-app>

          <!-- Dialog Start -->
    <div>
      <v-row justify="center">
        <v-dialog v-model="auth_error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              Unauthorized!
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogUnauthorized">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              Something went wrong...
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogError">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- Dialog Close -->
    <div class="infoMainClass">
    <div
      v-show="show_header"
      style="
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-left: 5px;
      margin: -1px;

      "
        class="header"
      >
      <div style="display: flex; flex-direction: row; width: 100%" >
        <div
          style="width: 10%; padding-top: 6px"
          align="left"
          v-on:click="redirectToHomePage()"
          >
          <img
          src="https://img.icons8.com/material-outlined/20/000000/left.png"
          />
        </div>
        <div style="width: 80%" align="left">
        <span style="font-weight: 600; font-size: 15pt"
        >Information</span
        >
        </div>
      </div>
       <div
            v-if="!isLogedIn"
          style="width: 10%; padding-top: 2px; font-weight:700; color:#1467BF"
          align="left"
          v-on:click="redirectToLoginPage()"
          >
            login
        </div>
    </div>


  <v-col cols="12"  >
     <div style="display:flex; flex-direction:column;">
         <div>
             <img  class="BannerClass" style="width:100%" src='https://s3iconimages.mymedicine.com.mm/docConsultationBanner.png' >
         </div>
         <div style="padding-top: 10px;line-height:25px" align="left">
             <h5>{{ $t("Customer.DoctorConsultationStaticContent.First_Question") }}</h5>
             <p style="padding-top:10px; font-size:14px;line-height:25px;text-align: justify;">
               {{ $t("Customer.DoctorConsultationStaticContent.First_Answer") }}
             </p>
         </div>
         <div style="padding-top: 10px;line-height:25px" align="left">
             <h5>{{ $t("Customer.DoctorConsultationStaticContent.Second_Question") }}</h5>
             <p style="padding-top:10px; font-size:14px;line-height:25px">
               {{ $t("Customer.DoctorConsultationStaticContent.Second_Answer") }}
             </p>
         </div>
          <div v-if="$store.state.locale =='en'">
           <img  style="width:100%; padding-top:10px; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/specialization_en.jpeg' >
        </div>
         <div v-if="$store.state.locale =='mm'">
           <img  style="width:100%; padding-top:10px; border-radius:8px" src='https://s3iconimages.mymedicine.com.mm/specialization_mm.png' >
        </div>
         <div style="padding-top: 10px;line-height:25px" align="left">
             <h5>{{ $t("Customer.DoctorConsultationStaticContent.Third_Question") }}</h5>
             <p style="padding-top:10px; font-size:14px;line-height:25px">
               {{ $t("Customer.DoctorConsultationStaticContent.Third_Answer") }}
             </p>
         </div>
         <v-btn width="80%" rounded color="primary" style="margin: 0% 10%;" @click="redirectToBookingPage()" >
                {{ $t("Customer.DoctorConsultationStaticContent.Consult_Now") }}
         </v-btn>
     </div>
</v-col>
</div>
</v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import bottomnavbar from "./bottomnavbar.vue";

export default {
  name: "DoctorConsultationInfo",
  data() {
    return {
      auth_error_enable: false,
      error_enable: false,
      pageName: "DoctorConsultationInfo",
      isMobile:false,
      isLogedIn:false,
      show_header:true,
    };
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    if(this.$store.state.locale == 'en')
      document.title = "Doctor Consultation Information"
    else
      document.title = 'ဆရာဝန်နှင့်ဆွေးနွေးတိုင်ပင်ခြင်းအချက်အလက်များ'
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");

    //logging page hit - start
    if(this.currentCustomer)
    {
      let x =  localStorage.getItem("currentURL")
      let pageName = "DoctorConsultationLandingScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName)
      console.log(document.referrer);
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
      .then((logSuccess) =>{

      })
      .catch((logError) =>{

      });
    }
    //logging page hit - end
    var access = this.$cookies.get("access");
     this.isLogedIn = true;
    },
  components: {
    NavBar,
    bottomnavbar,
  },
  methods: {
      redirectToBookingPage(){
            this.$router.push({
        name: "BookAppointment",
      });
      },
    closeDialogUnauthorized(){
    this.auth_error_enable = false;
    this.$router.push({
        name: "Login",
      });
    },
    redirectToLoginPage(){
         this.$router.push({
        name: "Login",
      });
    },
    closeDialogError(){
    this.error_enable = false;
    },

    redirectToHomePage() {
      this.$router.go(-1);
    },
    // redirectToCallpage(meetingId) {
    //   this.$router.push({
    //    path: '/customer/videocall/'+ meetingId
    //  })
    // }
  }
};
</script>

<style>
/* @media (min-width: 800px){
  .infoMainClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .BannerClass{
    width: 95% !important;
    height: auto;
  }
  } */

</style>